body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h30page {
  height: 30vh;
}

.hpage {
  height: 50vh;
}

.h70page {
  height: 70vh;
}

.page {
  height: 100vh;
}

.bg-lightsteelblue {
  background-color: lightsteelblue;
}

.h-5 {
  height: 5%;
}

.h-10 {
  height: 10%;
}

.h-15 {
  height: 15%;
}

.h-20{
  height: 20%;
}


.h-30 {
  height: 30%;
}

.h-45 {
  height: 45%;
}

.h-55 {
  height: 55%;
}

.h-60 {
  height: 60%;
}

.h-65 {
  height: 65%;
}

.h-70 {
  height: 70%;
}

.h-75 {
  height: 75%;
}

.h-80 {
  height: 80%;
}

.h-85 {
  height: 85%;
}


#downArrow {
  height: auto;
  width: auto;
}

.text-justify {
  text-align: justify;
}